import React, { RefObject, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import { useSafeState, useMount, useUpdate, useMemoizedFn, useUnmount } from 'ahooks';
import { useLocation, useSearchParams, useHistory } from 'react-router-dom';

import { Grid, Button, Space, Modal, Radio, Tabs, Image  } from 'antd-mobile'
import { ShareIcon } from 'component/icons'
import { BackupTitle } from 'pages/utils/utils'
import { getDisplayWeekTime, getDisplayTime, getFixDisplayTime } from 'model/util/logic'
import { sendBackToApp, requestTokenFromApp, sendSharedToApp } from 'model/msg/msg'
import { userEventReg, userEventUnreg, USER_EVENT, setUserToken } from 'model/user/user'
import { mkResultShareUrl, getResultUrlHasTitle, getResultUrlIsShared, getResultUrlOptime, getUnderApproval, getUrlIsUnderApproval } from "model/recognition/recognition"
import { SHARE_TITLE, UNDER_APPROVAL } from "model/util/comm"
import { getUrlIsInnerJump } from 'model/information/info'

import { COLOR } from 'component/comm';
const PAGE = 'page-result'
// -----------------------------------------------------------------------
const ContentTitle = forwardRef((props = {}, ref) => {
    let { title, titleColor, url, hasTitle, opTime, shareOnClickHd, isShare, noShare, isInnerJump } = props
    const history = useHistory()

    const backOnClickHd = () => {
        if (!isInnerJump) {
            sendBackToApp(url)
        }
        history.goBack()
    }

    let hintText = "您的评估结果为"
    if (getUnderApproval()) {
        hintText = "经智能图像分析，试纸显色区域结果为"
    }

    const getBackContent = () => {
        console.log("@@@@@@@@@@@@@@@@@: isShare = %o", isShare)

        if (isShare) {
            return undefined
        }

        if (hasTitle) {
            return (
                <BackupTitle backOnClickHd={backOnClickHd} title="检测结果" shareOnClickHd={shareOnClickHd} noShare={noShare}/>
            )
        }

        if (noShare) {
            return undefined
        }   

        return (
            <>
                <div className='row_space_small' />
                <div className="row_left">
                    <div style={{display: "flex", justifyContent: "right", flex: 1, marginRight: "16px"}}>
                        <div onClick={shareOnClickHd}>
                            <ShareIcon style={{fontSize: "20px"}}/>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const getDisplayOpTime = () => {
        if (opTime) {
            let tmpTime = getFixDisplayTime(opTime)
            if (tmpTime) {
                return tmpTime
            }
        }

        return getDisplayTime()
    }

    return (
        <>
            {getBackContent()}
            <div className='row_space_middle'/>

            <div className='row_center'>
                <span style={{fontSize: "14px", fontWeight: 400}}>{hintText}</span>
            </div>

            <div className='row_space_middle'/>

            <div className='row_center'>
                <span style={{fontSize: "20px", fontWeight: 500, color: titleColor}}>{title}</span>
            </div>

            <div className='row_space_middle'/>
            <div className='row_center'>
                <span style={{fontSize: "14px", fontWeight: 400, color: COLOR.GRAY_RESULT}}>评估时间：{getDisplayOpTime()}</span>
            </div>

            <div className='row_space_middle'/>
        </>
    )
})

const ABOContent = forwardRef((props = {}, ref) => {
    let { animalType, picSrc, personalityTab, advantageTab, riskTab, suggestTab, url, hasTitle, opTime, isShare, isInnerJump } = props

    const shareOnClickHd = () => {
        const shareUrl = mkResultShareUrl(url, opTime)
        let desc = "我分享了我的血型性格-->" + animalType + "，快来看吧！"
        sendSharedToApp(shareUrl, SHARE_TITLE.description(SHARE_TITLE.AOB), desc)
    }

    return (
        <>
            <ContentTitle title={animalType} url={url} hasTitle={hasTitle} opTime={opTime} 
            shareOnClickHd={shareOnClickHd} isShare={isShare} isInnerJump={isInnerJump} />

            <div className='resultBG'>
                <div className='pic'>
                    <Image src={picSrc} />
                </div>
                <div className="triangle"></div>
                <div className='row_space_big'/>
                <div className='row_space_small'/>
                <Tabs 
                    activeLineMode='fixed'
                    style={{
                        '--title-font-size': '16px',
                        '--fixed-active-line-width': '30px',
                    }}
                >
                    <Tabs.Tab title="人格分析" key="personality">
                        <div className='resultTab'>{personalityTab}</div>
                    </Tabs.Tab>

                    <Tabs.Tab title="健康优势" key="advantage">
                        <div className='resultTab'>{advantageTab}</div>
                    </Tabs.Tab>

                    <Tabs.Tab title="健康风险" key="risk">
                        <div className='resultTab'>{riskTab}</div>
                    </Tabs.Tab>

                    <Tabs.Tab title="健康建议" key="suggest">
                        <div className='resultTab'>{suggestTab}</div>
                    </Tabs.Tab>
                </Tabs>
            </div>
        </>
    )
})

const PressContent = forwardRef((props = {}, ref) => {
    let { title, titleColor, picSrc, personalityTab, suggestTab, pressValue, titleTextColor, url, hasTitle, opTime, isShare, isInnerJump } = props

    const shareOnClickHd = () => {
        const shareUrl = mkResultShareUrl(url, opTime)
        let desc = "我分享了我的压力测试结果-->" + title + "，快来看吧！"
        sendSharedToApp(shareUrl, SHARE_TITLE.description(SHARE_TITLE.CORTISOL), desc)
    }

    let tabTitleA = "压力分析"
    let tabTitleB = "健康建议"
    if (getUnderApproval()) {
        tabTitleA = "免责声明"
        tabTitleB = "数据参考引用"
    }
    
    return (
        <>
            <ContentTitle title={title} titleColor={titleColor} url={url} hasTitle={hasTitle} opTime={opTime} 
            shareOnClickHd={shareOnClickHd} isShare={isShare} isInnerJump={isInnerJump}/>

            <div className='resultBG'>
                <div className='pic'>
                    <Image src={picSrc} />
                </div>
                <div className='picTitle'>
                    <span style={{fontSize: "36px", color: titleTextColor}}>{pressValue}</span>
                    <span style={{fontSize: "20px", color: titleTextColor, marginTop: "5px"}}>%</span>
                </div>

                <div className="triangle"></div>
                <div className='row_space_big'/>
                <div className='row_space_small'/>

                <Tabs 
                    activeLineMode='fixed'
                    style={{
                        '--title-font-size': '16px',
                        '--fixed-active-line-width': '30px',
                    }}
                >
                    <Tabs.Tab title={tabTitleA} key="personality">
                        <div className='resultTab'>{personalityTab}</div>
                    </Tabs.Tab>

                    <Tabs.Tab title={tabTitleB} key="suggest">
                        <div className='resultTab'>{suggestTab}</div>
                    </Tabs.Tab>

                </Tabs>
            </div>

        </>
    )
})

const ResultKoalaPage = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const hasTitle = getResultUrlHasTitle(searchParams)
    const opTime = getResultUrlOptime(searchParams)
    const isShare = getResultUrlIsShared(searchParams)
    const isInnerJump = getUrlIsInnerJump(searchParams)

    const personalityTab = (
        <>
            <div className='title'>勤奋认真</div>
            <div className="text">考拉型的人通常非常努力和认真，对自己的工作和学习都非常投入，并且能一丝不苟地完成。他们有强烈的责任心和自我驱动力，能够在困难和压力下坚持不懈地完成任务。</div>

            <div className='title'>细腻敏感</div>
            <div className="text">考拉型人格通常很注重细节和情感，能够敏锐地察觉到他人的情绪和需求，并给予关心和支持。他们往往有较强的同理心和洞察力，能够与人建立良好的关系。</div>

            <div className='title'>有耐心和毅力</div>
            <div className="text">考拉型的人通常很有耐心和毅力，能够面对困难和挫折，并坚持下去。他们往往能够克服困难，迎接挑战，并取得成功。</div>

            <div className='title'>有创造力</div>
            <div className="text">考拉型的人通常很有创造力，能够从不同的角度思考问题，并提出独特的解决方案。他们往往能够在艺术、音乐、文学等领域发挥出自己的天赋和才华。</div>
        </>
    )

    const advantageTab = (
        <>
            <div className='title'>1</div>
            <div className="text">考拉型的人通常具有较好的忍耐力和耐受能力，因此平时不太容易受到疾病的困扰。</div>

            <div className='title'>2</div>
            <div className="text">考拉型的人身体比较灵巧，通常具有较高的灵敏度和协调性。</div>
        </>
    )

    const riskTab = (
        <>
            <div className='title'>1</div>
            <div className="text">考拉型的人血液黏稠度较高，容易升高，这增加了患脑梗塞的风险。</div>

            <div className='title'>2</div>
            <div className="text">考拉型的人血小板黏附率明显高于正常对照组，这也与脑梗塞的发生有关。</div>

            <div className='title'>3</div>
            <div className="text">考拉型的人消化系统功能不强，胃酸含量低，较缺少消化酶，这增加了患消化系统疾病的风险。</div>
        </>
    )

    const suggestTab = (
        <>
            <div className='title'>生活方面</div>
            <div className="text">考拉型的人通常比较细心、耐心、专注，但容易受到外界影响，因此需要注意心理调节和情绪管理。在日常生活中，可以多参加一些放松的活动，如散步、慢跑、瑜伽等，以缓解压力和焦虑。</div>

            <div className='title'>学习方面</div>
            <div className="text">考拉型的人通常很有计划性和条理性，可以利用这一优势制定学习计划。在制定计划时，可以结合自己的时间安排和目标，确保每天都有足够的时间用于学习和提高自己的能力。同时，要注意培养耐心和毅力，学会在任务或项目中保持冷静和专注。</div>
            
            <div className='title'>工作方面</div>
            <div className="text">考拉型的人通常很有责任心和自我驱动力，适合从事需要细心和专注的职业，如医生、护士、编辑等。在工作中，要注意与同事和上级的沟通，避免因为过度专注于自己的任务而忽略了团队合作。</div>

            <div className='title'>健康方面</div>
            <div className="text">考拉型的人应该多吃素食，减少肉食的摄入量。这样可以降低血液黏稠度，增强免疫系统功能。同时，适当摄入富含膳食纤维的食物可以帮助消化和预防消化系统疾病。此外，考拉型的人应该注意保护肠胃，避免过度饮食刺激性食物和饮料。</div>
        </>
    )

    return (
        <ABOContent 
            animalType="考拉（A型）" 
            picSrc={process.env.PUBLIC_URL + "/images/koala.png"}
            url="/result/dsiplay/ABO/kaola"
            personalityTab={personalityTab}
            advantageTab={advantageTab}
            riskTab={riskTab}
            suggestTab={suggestTab}
            hasTitle={hasTitle}
            opTime={opTime}
            isShare={isShare}
            isInnerJump={isInnerJump}
        />
    )
})

const ResultOwlPage = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const hasTitle = getResultUrlHasTitle(searchParams)
    const opTime = getResultUrlOptime(searchParams)
    const isShare = getResultUrlIsShared(searchParams)
    const isInnerJump = getUrlIsInnerJump(searchParams)
    
    const personalityTab = (
        <>
            <div className='title'>乐观积极</div>
            <div className="text">猫头鹰型的人通常很乐观积极，能够看到问题的积极面，并保持乐观的态度。他们往往能够传递正能量，鼓励他人积极面对困难和挑战。</div>

            <div className='title'>热情开朗</div>
            <div className="text">猫头鹰型人格通常很热情开朗，能够与人建立良好的关系，并享受与他人交流和互动。他们往往能够积极融入社交圈子，成为团体的中心。</div>

            <div className='title'>独立自主</div>
            <div className="text">猫头鹰型的人通常很独立自主，能够独立思考和决策，并勇于承担责任。他们往往能够独立完成任务，展现出自己的才华和能力。</div>

            <div className='title'>灵活创新</div>
            <div className="text">猫头鹰型的人通常很灵活创新，能够适应不同的环境和情况，并寻找新的机会和解。</div>

            <div className='title'>有自信心</div>
            <div className="text">猫头鹰型的人通常很有自信心，能够相信自己的能力和价值，并勇于面对挑战。</div>
        </>
    )

    const advantageTab = (
        <>
            <div className="text">猫头鹰型的人通常具有强大的消化功能和适应能力，能够很好地吸收各种营养物质。这使得猫头鹰型的人在摄入动物性食物或植物性食物时，能够快速高效地新陈代谢，从而保持身体健康。</div>
        </>
    )

    const riskTab = (
        <>
            <div className='title'>1</div>
            <div className="text">猫头鹰型的人在长期压力下，健康最容易亮起红灯，存在一些健康上的劣势和隐患。</div>

            <div className='title'>2</div>
            <div className="text">猫头鹰型的人容易患上慢性疲劳综合征，对流行性感冒病毒的防卫能力最差，这也增加了患流行性感冒的风险。</div>

            <div className='title'>3</div>
            <div className="text">猫头鹰型的人新陈代谢受到食物中凝集素蛋白质的影响，这也增加了患肥胖的风险。</div>
        </>
    )

    const suggestTab = (
        <>
            <div className='title'>生活方面</div>
            <div className="text">猫头鹰型的人通常比较灵活、开放、有创意，但也容易受到外界影响，因此需要注意自我管理和情绪调节。在日常生活中，可以多参加一些社交活动，如聚会、交友等，以拓展自己的人脉和增加社交经验。</div>

            <div className='title'>学习方面</div>
            <div className="text">猫头鹰型的人在学习中，可以注重培养自己的独立思考和创新能力，同时也要学会与人合作和沟通。</div>

            <div className='title'>工作方面</div>
            <div className="text">猫头鹰型的人通常很有创意和想象力，适合从事需要创新和创造力的职业，如设计师、策划师、市场营销师等。在工作中，要注意与同事和上级的沟通，避免因为过度专注于自己的创意而忽略了团队合作。</div>

            <div className='title'>健康方面</div>
            <div className="text">猫头鹰型的人可以采取一些健康策略来保持身体健康。首先，猫头鹰型的人可以吃更多的羊肉，这可以增强他们的免疫系统功能。此外，适当补充含镁的食物，如松子。</div>
        </>
    )

    return (
        <ABOContent 
            animalType="猫头鹰（B型）" 
            picSrc={process.env.PUBLIC_URL + "/images/owl.png"}
            url="/result/dsiplay/ABO/owl"
            personalityTab={personalityTab}
            advantageTab={advantageTab}
            riskTab={riskTab}
            suggestTab={suggestTab}
            hasTitle={hasTitle}
            opTime={opTime}
            isShare={isShare}
            isInnerJump={isInnerJump}
        />
    )
})

const ResultTigerPage = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const hasTitle = getResultUrlHasTitle(searchParams)
    const opTime = getResultUrlOptime(searchParams)
    const isShare = getResultUrlIsShared(searchParams)
    const isInnerJump = getUrlIsInnerJump(searchParams)

    const personalityTab = (
        <>
            <div className='title'>自信果断</div>
            <div className="text">老虎型的人通常非常自信，能够果断地做出决策，并勇于面对挑战和困难。他们通常具有很强的自我意识和自信心，能够以积极的态度面对生活中的各种问题。</div>

            <div className='title'>理智冷静</div>
            <div className="text">老虎型的人通常很理智冷静，能够控制自己的情绪和行为，并做出合理的决策。他们通常不会轻易受到情感的影响，而是以逻辑和理性来处理问题。</div>

            <div className='title'>逻辑思考</div>
            <div className="text">老虎型的人通常具有很强的逻辑思考能力，能够以有条理的方式分析和解决问题。他们通常能够迅速地找到问题的根源，并给出有效的解决方案。</div>

            <div className='title'>目标导向</div>
            <div className="text">老虎型的人通常具有明确的目标和计划，并能够为之不懈努力。他们通常具有很强的目的性和计划性，能够按照计划逐步实现目标。</div>
        </>
    )

    const advantageTab = (
        <>
            <div className='title'>1</div>
            <div className="text">老虎型的人具有很强的自身免疫功能和抵抗力，能够很好地保护身体不受各种疾病和病毒的侵袭。这可能是因为老虎型的人具有比较高的荷尔蒙水平，能够更好地抵抗炎症和感染。</div>

            <div className='title'>2</div>
            <div className="text">根据一些研究，老虎型的人的平均寿命明显较长。这可能是因为老虎型的人具有更好的免疫系统和抵抗力，能够更好地抵抗疾病和感染。</div>

        </>
    )

    const riskTab = (
        <>
            <div className='title'>1</div>
            <div className="text">老虎型的人血液较稀，不利于血液的凝固，因此容易发生出血不止的现象。</div>

            <div className='title'>2</div>
            <div className="text">老虎型的人甲状腺功能不稳定，容易发生甲状腺问题，如甲状腺功能亢进或甲状腺功能减退等。</div>

            <div className='title'>3</div>
            <div className="text">老虎型的人胰岛素含量偏低，相对于其他类型的人更容易患上糖尿病。</div>
        </>
    )

    const suggestTab = (
        <>
            <div className='title'>生活方面</div>
            <div className="text">老虎型的人通常比较自信、果断、有领导力，适合从事需要领导力和决策力的职业。在日常生活中，可以多参加一些挑战性的活动，如登山、探险等，以增强自己的自信和领导力。</div>

            <div className='title'>学习方面</div>
            <div className="text">老虎型的人通常很有自信和领导力，适合从事需要领导力和决策力的职业。在学习中，可以注重培养自己的决策能力和领导力，同时也要学会多听取他人的意见和建议。</div>

            <div className='title'>工作方面</div>
            <div className="text">老虎型的人通常很有领导力和决策力，适合从事管理、销售、金融等职业。在工作中，要注意与同事和上级的沟通，避免因为过度专注于自己的决策而忽略了团队合作。</div>

            <div className='title'>健康方面</div>
            <div className="text">老虎型的人吸收能力很强，特别是对于高蛋白食物，如肉类食物，其养分能在体内得到最好的吸收。因此，老虎型的人可以多吃瘦肉，但需要注意的是，肉类食物应以瘦肉为主，少吃肥肉，以防止发胖。其次，保持睡眠规律对于老虎型的人来说非常重要。</div>

        </>
    )

    return (
        <ABOContent 
            animalType="老虎（O型）"
            picSrc={process.env.PUBLIC_URL + "/images/tiger.png"}
            url="/result/dsiplay/ABO/tiger"
            personalityTab={personalityTab}
            advantageTab={advantageTab}
            riskTab={riskTab}
            suggestTab={suggestTab}
            hasTitle={hasTitle}
            opTime={opTime}
            isShare={isShare}
            isInnerJump={isInnerJump}
        />
    )
})

const ResultPeacockPage = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const hasTitle = getResultUrlHasTitle(searchParams)
    const opTime = getResultUrlOptime(searchParams)
    const isShare = getResultUrlIsShared(searchParams)
    const isInnerJump = getUrlIsInnerJump(searchParams)
    
    const personalityTab = (
        <>
            <div className='title'>冷静理智</div>
            <div className="text">孔雀型的人通常非常冷静理智，能够控制自己的情绪和行为，并做出合理的决策。他们通常不会轻易受到情感的影响，而是以逻辑和理性来处理问题。</div>

            <div className='title'>敏感聪明</div>
            <div className="text">孔雀型的人通常很敏感聪明，能够察觉到他人的情绪和需求，并给予关心和支持。他们往往能够迅速地找到问题的根源，并给出有效的解决方案。</div>

            <div className='title'>善于观察</div>
            <div className="text">孔雀型的人通常具有敏锐的观察力和洞察力，能够注意到细节和模式，并从中获取有价值的信息。他们往往能够在科学、艺术等领域发挥出自己的天赋和才华。</div>

            <div className='title'>适应能力强</div>
            <div className="text">孔雀型的人通常具有很强的适应能力和应变能力，能够应对不同的情况和环境。他们往往能够迅速地适应新的环境和新的人际关系，融入其中并发挥自己的优势</div>

            <div className='title'>有创意有想象力</div>
            <div className="text">孔雀型的人通常很有创意和想象力，能够产生新的想法和创意，并勇于尝试新的领域和机会。他们往往能够在艺术、文学、音乐等领域发挥出自己的创造力和想象力。</div>
        </>
    )

    const advantageTab = (
        <>
            <div className='title'>1</div>
            <div className="text">孔雀型的人在免疫系统功能方面具有很强的适应性。他们能够根据不同的环境条件和病原体类型，产生相应的免疫反应，从而有效地保护身体免受疾病的侵害。</div>

            <div className='title'>2</div>
            <div className="text">孔雀型的人通常拥有考拉型和猫头鹰型的双重优点。例如，他们可以同时消化吸收动物性和植物性食物中的营养物质，这有助于他们保持身体健康。</div>

            <div className='title'>3</div>
            <div className="text">孔雀型的人通常具有较强的神经系统和代谢系统功能，这使得他们能够更好地应对压力和疲劳。</div>
        </>
    )

    const riskTab = (
        <>
            <div className='title'>1</div>
            <div className="text">孔雀型的人的胃酸含量比较低，这会影响对营养物质的消化吸收，增加消化不良的风险。</div>

            <div className='title'>2</div>
            <div className="text">由于孔雀型的人对外界环境中的细菌比较敏感，所以细菌容易随吸气进入呼吸道及肺部，增加患呼吸系统疾病的风险。</div>

            <div className='title'>3</div>
            <div className="text">孔雀型的人胆固醇含量相对较高，血脂也容易升高，这增加了患心脏病的风险。</div>
        </>
    )

    const suggestTab = (
        <>
            <div className='title'>生活方面</div>
            <div className="text">孔雀型的人通常比较聪明、敏感、有逻辑性，适合从事需要逻辑思考和分析的职业。在日常生活中，可以多参加一些智力挑战的活动，如解谜游戏、数独等，以增强自己的逻辑思考和分析能力。</div>

            <div className='title'>学习方面</div>
            <div className="text">孔雀型的人通常很有逻辑思考和分析能力，适合从事需要逻辑思考和分析的职业。在学习中，可以注重培养自己的逻辑思考能力和分析能力，同时也要学会与人建立良好的人际关系和沟通技巧。</div>

            <div className='title'>工作方面</div>
            <div className="text">孔雀型的人通常很有逻辑思考和分析能力，适合从事研发、设计、数据分析等职业。在工作中要注意与同事和上级的沟通同时也要学会建立良好的人际关系和沟通技巧以利于自己能够更好地发挥自己的优势进而取得更好的成绩和发展。</div>

            <div className='title'>健康方面</div>
            <div className="text">首先，应该远离烟酒，因为烟酒会对呼吸道产生刺激，增加患呼吸系统疾病的风险。其次，孔雀型的人应该谨慎食用过敏性食物，如鱼、虾、巧克力、羊肉等，以避免引起过敏反应。此外，孔雀型的人还应该注意保持适当的运动和饮食习惯，以帮助消化和吸收营养物质。</div>

        </>
    )
    
    return (
        <ABOContent 
            animalType="孔雀（AB型）"
            picSrc={process.env.PUBLIC_URL + "/images/peacock.png"}
            url="/result/dsiplay/ABO/peacock"
            personalityTab={personalityTab}
            advantageTab={advantageTab}
            riskTab={riskTab}
            suggestTab={suggestTab}
            hasTitle={hasTitle}
            opTime={opTime}
            isShare={isShare}
            isInnerJump={isInnerJump}
        />
    )
})

const ResultZeroPressPage = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const hasTitle = getResultUrlHasTitle(searchParams)
    const opTime = getResultUrlOptime(searchParams)
    const isShare = getResultUrlIsShared(searchParams)
    const underApproval = getUrlIsUnderApproval(searchParams)
    const isInnerJump = getUrlIsInnerJump(searchParams)
    
    let personalityTab = (
        <>
            <div className="text">这个分数表示你对所遭遇的压力毫不在乎，好像并没有发生过一样。这对你的健康不会有什么负面的影响、偶尔会有一些时间感觉压力较大，但你有能力去冲破压力，并很快回到平衡的状态。这表明你是一个相当放松的人。也许你对于所遇到的各种压力，并没有将它们解释为威胁，但同时你的生活也缺乏适度的兴奋，因此，趣味相对有限。</div>
        </>
    )

    let suggestTab = (
        <>
            <div className="text">你的评估结果非常好，在压力的舒适区间不仅可以帮助我们更好地完成任务，同时也可以增加个体的生理健康水平。保持积极乐观的态度是维持低压力的最佳方式。无论遇到什么样的挫折和压力，我们都应该在面对困难时，保持一个乐观的心态，这样可以让我们更加坚强和勇气。同时不要过分追求完美，让自己沉浸在快乐的氛围中。享受美食、旅行和与朋友的时光，让快乐成为生活的常态。</div>

            <div className="text">记得时刻关注自己身心健康，保持活力健康是快乐的基石，每天运动、保持良好的作息和饮食习惯，保持活力，才能享受生活的美好。要知道生活中的每一个瞬间都是珍贵的，不要忽略身边的幸福，用心感受每一个当下的美好，让快乐时刻伴随着你。我们每个人都有自己独特的魅力，不必刻意追求别人的认同，爱自己，接受自己的不完美，才能真正快乐起来。</div>

            <div className="text">维持适度的压力是生活中非常重要的事情，这可以帮助我们获得身心健康，并且让我们面对忙碌的工作和学业的时候更加轻松。保持良好的抗压状态，可以让我们拥有一个更加舒适和健康的生活。让我们一起放下他人的期望，关注内心的需求，在繁忙的生活中，找到缓解压力的方法。</div>
        </>
    )

    let displayTitle = "零压力"

    if (getUnderApproval()) {
        personalityTab = <MianzeTab />
        suggestTab = <QuoteTab />
        displayTitle = "愉悦度高"
    }

    return (
        <PressContent 
            title={displayTitle}
            titleColor={COLOR.PRIMARY}
            titleTextColor={COLOR.PRIMARY}
            pressValue={10}
            picSrc={process.env.PUBLIC_URL + "/images/zeroPress.png"}
            url="/result/dsiplay/cortisol/zeroStress"
            personalityTab={personalityTab}
            suggestTab={suggestTab}
            hasTitle={hasTitle}
            opTime={opTime}
            isShare={isShare}
            isInnerJump={isInnerJump}
        />
    )
})

const ResultMediumStressPage = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const hasTitle = getResultUrlHasTitle(searchParams)
    const opTime = getResultUrlOptime(searchParams)
    const isShare = getResultUrlIsShared(searchParams)
    const underApproval = getUrlIsUnderApproval(searchParams)
    const isInnerJump = getUrlIsInnerJump(searchParams)

    let personalityTab = (
        <>
            <div className="text">评估结果显示，你最近一段时间处于中度压力区间，它可能正开始对你的健康产生不利。如长期处于中度压力之下，会让你产生各种消极的情绪，甚至出现烦躁易怒的现象。你可以仔细反省自己对压力如何反应，并试着学习在压力出现时如何控制自己的情绪，以消除生理激活反应，或者选择合适的放松方式进行训练。</div>
        </>
    )

    let  suggestTab = (
        <>
            <div className="text">建议你合理规划作息时间，确保工作之余有足够的时间用于休息、运动和娱乐等活动，适当的放松策略可以帮助你处理工作中的挑战和困难。多和信任的人分享自己的感受和困扰，能够得到理解和支持，减轻心理负担。计划一个假期来放松身心，放松心情，可以让你彻底放下压力世界的忙碌和繁杂，享受身心愉悦；写日记是一个非常有用的减压方式，当你写下自己的情感和想法时，你的情绪和压力就会得到释放，同时你也可以更好地了解和认识自己。</div>

            <div className="text">当你感到紧张和情绪低落时，听听音乐或者看看电影，放松自己的情绪。这样可以让你远离焦虑和抑郁情绪；经常喝咖啡或其他含有咖啡因的饮品，会让你短时间内感到兴奋和紧张。减少咖啡因的摄入可以让你的身体逐渐恢复，同时也可以缓解压力；学习新的技能、尝试新的爱好和兴趣可以让你多姿多彩的生活更加有意思，适当的“偷懒”也并不是坏事。均衡的饮食可以帮助你保持健康，并且增强身体的免疫力和抵御力。同时要充分摄取蔬菜和水果等，这样可以让身体更加健康。</div>

            <div className="text">近些年来人们随着生活、学习压力不断增大，中度压力的人群也越发多见。中度压力人群不必过于担心，这种状态并没有达到十分严重的地步，一般通过积极的自我调节和锻炼都是可以及时恢复的。</div>
        </>
    )

    let displayTitle = "中度压力"
    
    if (getUnderApproval()) {
        personalityTab = <MianzeTab />
        suggestTab = <QuoteTab />
        displayTitle = "愉悦度中"
    }
    
    return (
        <PressContent 
            title={displayTitle}
            titleColor={COLOR.GOLD_PRESS}
            titleTextColor={COLOR.GOLD_PRESS}
            pressValue={50}
            picSrc={process.env.PUBLIC_URL + "/images/mediumStress.png"}
            url="/result/dsiplay/cortisol/mediumStress"
            personalityTab={personalityTab}
            suggestTab={suggestTab}
            hasTitle={hasTitle}
            opTime={opTime}
            isShare={isShare}
            isInnerJump={isInnerJump}
        />
    )
})

const ResultSeriousStressPage = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const hasTitle = getResultUrlHasTitle(searchParams)
    const opTime = getResultUrlOptime(searchParams)
    const isShare = getResultUrlIsShared(searchParams)
    const underApproval = getUrlIsUnderApproval(searchParams)
    const isInnerJump = getUrlIsInnerJump(searchParams)
    
    
    let personalityTab = (
        <>
            <div className="text">评估结果显示，你最近一段时间处于重度压力区间。经常会有一些时间感觉压力很大，并且在冲破压力的时候感觉阻碍重重。也会出现注意力不集中、记忆力差等情况，在人际关系处理中也时常感到崩溃。你的行为会伤害自己，也可能会影响其他人。因此，对你来说，学习如何减少自己的压力反应是非常重要的。你可能需要花费很多时间做练习，学习控制自己的压力和情绪，在你尝试了很多方法后依然处于重度压力的情况下，你需要尽快寻求专业人员的帮助。</div>
        </>
    )

    let suggestTab = (
        <>
            <div className="text">如长期处于重度压力之下，要学会表达、发泄以及示弱，不要故作坚强；找到适于自己的放松途径；与亲朋好友倾诉。与信任的人分享自己的感受和压力，可以获得情感支持和建议；保证充足睡眠和规律作息。良好的睡眠习惯有助于保持身心的平衡和精神状态；保持积极的心态。面对压力时，尝试保持乐观态度，相信自己能够克服困难；运动可以释放紧张的情绪，增强身体免疫力，并且对身心健康有很大帮助，你可以选择跑步、瑜伽、游泳或其他你喜欢的运动方式。当你感到紧张或者压力大的时候，你可以尝试进行深呼吸或冥想等呼吸练习。这可以帮助你放松身心、集中注意力，并且缓解紧张情绪；尽量培养积极的心态，正确认识自己的能力和价值，接受自己的不足，并学会从失败中汲取教训，继续前进。</div>

            <div className="text">如果上述方法不奏效或者是收效甚微，不妨及时到医院看心理医生。与医生沟通之后，医生可以提供有效的解决方法，舒缓患者过重的心理压力。</div>
        </>
    )

    let displayTitle = "重度压力"

    if (getUnderApproval()) {
        personalityTab = <MianzeTab />
        suggestTab = <QuoteTab />
        displayTitle = "愉悦度低"
    }

    return (
        <PressContent 
            title={displayTitle}
            titleColor={COLOR.RED_PRESS}
            titleTextColor={COLOR.GRAY_1}
            pressValue={80}
            picSrc={process.env.PUBLIC_URL + "/images/seriousStress.png"}
            url="/result/dsiplay/cortisol/seriousStress"
            personalityTab={personalityTab}
            suggestTab={suggestTab}
            hasTitle={hasTitle}
            opTime={opTime}
            isShare={isShare}
            isInnerJump={isInnerJump}
        />
    )
})

const NoResultPage = forwardRef((props = {}, ref) => {
    const { search } = useLocation();
    const history = useHistory()
    
    const searchParams = new URLSearchParams(search);
    const hasTitle = getResultUrlHasTitle(searchParams)
    const opTime = getResultUrlOptime(searchParams)
    const underApproval = getUrlIsUnderApproval(searchParams)
    const isInnerJump = getUrlIsInnerJump(searchParams)

    const rfCourseClickHd = () => {
        // 导航到教程页面
        history.push("/course/needBack")
    }

    const getRfButton = () => {
        if (getUnderApproval()) {
            return undefined
        }

        return (
            <div style={{paddingLeft: "16px", paddingRight: "16px"}}>
                <Button block color='success' shape="rounded" size='large' onClick={rfCourseClickHd}>参考教程指导</Button>
            </div>
        )
    }

    return (
        <>
            <ContentTitle title="试纸图像异常" 
            titleColor={COLOR.GRAY_13} 
            url="/result/dsiplay/noResult" 
            hasTitle={hasTitle} opTime={opTime} noShare={true} isInnerJump={isInnerJump}/>
                <div className='resultBG2'>
                    <div className='pic' >
                        <Image src={process.env.PUBLIC_URL + "/images/noResult.png"} />
                    </div>
                </div>

                <div style={{width: 100, height: "150px"}}/>

                {getRfButton()}
                
        </>
    )
})

const QuoteTab = forwardRef((props = {}, ref) => {
    return (
        <>
            <div className='row_space_middle'/>
            <div className='row_left'>
                <span style={{fontWeight: 500}}>识别结果解释可参考下面链接</span>
            </div>
            <div className='row_space_middle'/>
            <a href="https://www.ncbi.nlm.nih.gov/books/NBK538239/">https://www.ncbi.nlm.nih.gov/books/NBK538239/</a>
            <div className='row_space_middle'/>
            <a href="https://my.clevelandclinic.org/health/articles/22187-cortisol">https://my.clevelandclinic.org/health/articles/22187-cortisol</a>
            <div className='row_space_middle'/>
        </>
    )
})

const MianzeTab = forwardRef((props = {}, ref) => {
    return (
        <>
            <div>
            <div className='textP'>健康前哨APP提供的各类检测功能所生成的结果仅供参考，这些结果不应被视为专业医疗诊断、建议或治疗的替代品，不能作为诊断和治疗的直接依据。用户不应过度依赖这些结果，我们及合作单位对因用户过度依赖或误解检测结果而导致的任何损失或损害不承担责任。</div>
            </div>
        </>
    )
})

// -----------------------------------------------------------------------
export { ResultKoalaPage, ResultOwlPage, ResultTigerPage, ResultPeacockPage, ResultZeroPressPage, ResultMediumStressPage, 
    ResultSeriousStressPage, NoResultPage }

export { ContentTitle }