import React, { RefObject, forwardRef, useRef } from 'react'
import { useSafeState, useMount, useUpdate, useUnmount } from 'ahooks';
import { Grid, Button, Space, Modal, Radio, Tabs, Image  } from 'antd-mobile'
import { useHistory, useLocation } from 'react-router-dom'
import { COLOR } from 'component/comm';
import { BottomNavBar } from './navBar'
import { BackIcon } from 'component/icons'
import { sendBackToApp, requestTokenFromApp } from 'model/msg/msg'
import { userEventReg, userEventUnreg, USER_EVENT, setUserToken, getUserToken } from 'model/user/user'
import { InformationListPage } from 'pages/information/list'

const PAGE = 'page-home'
// -----------------------------------------------------------------------
const HomePage = forwardRef((props = {}, ref) => {
    const iosOnClickHd = () => {
        window.open("https://apps.apple.com/cn/app/%E5%81%A5%E5%BA%B7%E5%89%8D%E5%93%A8/id6503282733")
    }

    const androidOnClickHd = () => {
        window.open("https://a.app.qq.com/o/simple.jsp?pkgname=com.qianshao.app")
    }

    return (
        <div className="home">
            <div className='homeBG'>
                <div className='pic' >
                    <Image src={process.env.PUBLIC_URL + "/images/banner2.png"} />
                </div>
                <div className='title'>
                    <span>健康前哨</span>
                </div>

                <div className='desc'>
                    <span>贴心的健康小帮手</span>
                </div>
            </div>

            <div style={{margin: "24px 0px 0px 0px"}}/>
            
            <Grid columns={2} gap={16}>
                <div className='d_button_iphone'>
                    <Button shape="rounded" size="large" onClick={iosOnClickHd}>iPhone版</Button>
                </div>
                <div className='d_button_android'>
                    <Button shape="rounded" size="large" onClick={androidOnClickHd}>Android版</Button>
                </div>
                
            </Grid>
            <div style={{marginTop: "8px"}}/>
            <div className='hoText_desc'>
                请使用默认浏览器打开本网页下载
            </div>

            <div style={{margin: "16px 0px 16px 0px"}}/>

            <div className='hoText_title'>方欣健康前哨 智能识别</div>
            <div style={{margin: "8px 0px 0px 0px"}}/>
            <div className='hoText_desc'>AI秒级识别试纸结果，随时掌握健康动态</div>

            <div style={{margin: "16px 0px 0px 0px"}}/>

            <InformationListPage innerPage={true}/>
        </div>
    )
})


const ContentTitle = forwardRef((props = {}, ref) => {
    let { title, titleColor, url } = props

    const backOnClickHd = () => {
        sendBackToApp(url)
    }

    return (
        <>
            <div className='startLine'>
                <div className='row_left'>
                    <div onClick={backOnClickHd}>
                        <BackIcon style={{fontSize: "24px"}} />
                    </div>
                </div>
            </div>

            <div className='row_space_middle'/>

            <div className='row_space_middle'/>

            <div className='row_center'>
                <span style={{fontSize: "20px", fontWeight: 500, color: titleColor}}>页面开发中，敬请期待</span>
            </div>

            <div className='row_space_big'/>
            <div className='row_space_middle'/>
            <div className='row_space_middle'/>
        </>
    )
})

const DevelopmentPage = forwardRef((props = {}, ref) => {
    let {match} = props

    const url = match?.url

    const rfCourseClickHd = () => {
        requestTokenFromApp(url)
    }

    // const userEventHd = (event, data) => {
    //     if (event === USER_EVENT.TOKEN_CHANGED) {
    //         Modal.alert(
    //             {
    //                 title: data, 
    //             }
    //         )
    //     }
    // }

    const testFunc = () => {
        // if (window.setUserToken) {
        //     window.setUserToken("558888000")
        // }
        const token = getUserToken()
        setUserToken(token)
    }

    useMount(() => {
        // userEventReg(`${PAGE}-Development`, [USER_EVENT.TOKEN_CHANGED], userEventHd)
    })

    useUnmount(() => {
        // userEventUnreg(`${PAGE}-Development`)
    })

    return (
        <>
            <ContentTitle title="" titleColor={COLOR.GRAY_13} url={url}/>
                <div className='resultBG2'>
                    <div className='pic' onClick={testFunc}>
                        <Image src={process.env.PUBLIC_URL + "/images/noResult.png"} />
                    </div>
                </div>

                <div style={{width: 100, height: "240px"}}/>
        </>
    )
})






// -----------------------------------------------------------------------
export { HomePage, DevelopmentPage }
