import React, { Component } from 'react';
import { useSafeState, useMount, useUpdate, useMemoizedFn, useUnmount } from 'ahooks';
import { Switch, Route, useLocation, useSearchParams } from 'react-router-dom';


// ************************************************************
// 各模块路由表配置
// ************************************************************

import { HomePage, DevelopmentPage } from "pages/home/home"
import { ShopPage } from 'pages/shop/shop'
import { AboutPage, CompanyPage, PrivacyAgreementPage, UserAgreementPage } from 'pages/profile/about'
import { setUserToken } from 'model/user/user'
import { CoursePage, CourseHasBackPage } from 'pages/course/course'
import { CourseDetailPage } from 'pages/course/detial'
import { DetailPage, DetailT1Page, DetailT2Page, DetailT3Page, DetailT4Page, DetailNormalPage } from 'pages/information/detail'
import { InformationListPage } from 'pages/information/list'
import { ResultHistoryPage, ResultLatestPage, NoDataPage } from 'pages/recognition/history'
import { ResultKoalaPage, ResultOwlPage, ResultTigerPage, ResultPeacockPage, ResultZeroPressPage, ResultMediumStressPage, 
    ResultSeriousStressPage, NoResultPage } from 'pages/recognition/result'
import { CollectsPage } from 'pages/profile/collects'
import { HcgWeek0Page, HcgWeek1Page, HcgWeek2Page, HcgWeek3Page, HcgWeek4Page, HcgWeekBigPage } from 'pages/recognition/hcg'



// ---------------------------------------------------------------------------
function AppRoute(props) {
    let {match} = props

    const { search } = useLocation();
    
    // 保存 token 
    const searchParams = new URLSearchParams(search);
    const token = searchParams.get("token")
    if (token) {
        setUserToken(token)
        console.log("@@@@@@: set token = %o", token)
    }

    useMount(() => {
        window.setUserToken = setUserToken
    })

    useUnmount(() => {
        delete window.setUserToken
    })
    
    return (
        <Switch>
            <Route path={`${match?.path}`} exact component={ResultKoalaPage} />

            <Route path={`${match?.path}home`} exact component={HomePage} />
            <Route path={`${match?.path}shop`} exact component={ShopPage} />

            <Route path={`${match?.path}information`} exact component={InformationListPage} />
            <Route path={`${match?.path}information/banner`} exact component={CompanyPage} />

            
            <Route path={`${match?.path}profile/collection`} exact component={CollectsPage} />
            <Route path={`${match?.path}information/detail`} exact component={DetailPage} />
            <Route path={`${match?.path}information/detail/t1`} exact component={DetailT1Page} />
            <Route path={`${match?.path}information/detail/t2`} exact component={DetailT2Page} />
            <Route path={`${match?.path}information/detail/t3`} exact component={DetailT3Page} />
            <Route path={`${match?.path}information/detail/t4`} exact component={DetailT4Page} />
            <Route path={`${match?.path}information/normal/detail/:uuid`} exact component={DetailNormalPage} />

            <Route path={`${match?.path}course`} exact component={CoursePage} />
            <Route path={`${match?.path}course/needBack`} exact component={CourseHasBackPage} />
            <Route path={`${match?.path}course/detail/:uuid`} exact component={CourseDetailPage} />

            
            <Route path={`${match?.path}result/dsiplay/ABO/kaola`} exact component={ResultKoalaPage} />
            <Route path={`${match?.path}result/dsiplay/ABO/owl`} exact component={ResultOwlPage} />
            <Route path={`${match?.path}result/dsiplay/ABO/tiger`} exact component={ResultTigerPage} />
            <Route path={`${match?.path}result/dsiplay/ABO/peacock`} exact component={ResultPeacockPage} />

            <Route path={`${match?.path}result/dsiplay/cortisol/zeroStress`} exact component={ResultZeroPressPage} />
            <Route path={`${match?.path}result/dsiplay/cortisol/mediumStress`} exact component={ResultMediumStressPage} />
            <Route path={`${match?.path}result/dsiplay/cortisol/seriousStress`} exact component={ResultSeriousStressPage} />
            <Route path={`${match?.path}result/dsiplay/noResult`} exact component={NoResultPage} />

            <Route path={`${match?.path}result/dsiplay/HCG/week0`} exact component={HcgWeek0Page} />
            <Route path={`${match?.path}result/dsiplay/HCG/week1`} exact component={HcgWeek1Page} />
            <Route path={`${match?.path}result/dsiplay/HCG/week2`} exact component={HcgWeek2Page} />
            <Route path={`${match?.path}result/dsiplay/HCG/week3`} exact component={HcgWeek3Page} />
            <Route path={`${match?.path}result/dsiplay/HCG/week4`} exact component={HcgWeek4Page} />
            <Route path={`${match?.path}result/dsiplay/HCG/weekBig`} exact component={HcgWeekBigPage} />

            <Route path={`${match?.path}result/history`} exact component={ResultHistoryPage} />
            <Route path={`${match?.path}result/latest`} exact component={ResultLatestPage} />
            <Route path={`${match?.path}profile/about`} exact component={AboutPage} />
            <Route path={`${match?.path}profile/userAgreement`} exact component={UserAgreementPage} />
            <Route path={`${match?.path}profile/privacyAgreement`} exact component={PrivacyAgreementPage} />
            <Route path={`${match?.path}profile/company`} exact component={CompanyPage} />
            {/* <Route path={`${match?.path}profile/collects`} exact component={CollectsPage} /> */}

            <Route path={`${match?.path}test`} exact component={ResultLatestPage} />
            
        </Switch>
    );
}


export default AppRoute

